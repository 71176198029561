import React from 'react';

class MouseScroll extends React.PureComponent {

  render() {
    return (
      <div className="scroll-downs mt-5">
        <div className="mousey">
          <div className="scroller"></div>
        </div>
      </div>
    );
  }
};

export default MouseScroll;