import React from 'react';
import Navigation from './Navigation';
import MouseScroll from './MouseScroll';
import withSizes from 'react-sizes';

class Section extends React.PureComponent {

  render() {
    return (
      <div className={this.props.isDesktop ? this.props.background : ''}>
        <section className={this.props.isDesktop ? "mx-5" : "mx-4"}>
          <div className={this.props.fullScreen ? (
            this.props.isDesktop ? "vh-100" : "my-5") : "my-5"}>
            <div className="row h-100 justify-content-center align-items-center">
              <div className="col-12 text-center">
                <div className="row">
                  <div className="col-12 text-center">
                    { this.props.showNav ? <Navigation placeholder='Home'/> : ''}
                    <span className="lead">{this.props.header}</span>
                    { this.props.h1 ?
                      [
                        <h1 className={ this.props.isDesktop ? "display-3" : "display-5"}>{this.props.title}</h1>, 
                        <h2 className="lead subtitle">{this.props.subtitle}</h2>
                      ] :
                      [
                        <h2 className={ this.props.isDesktop ? "display-3" : "display-5"}>{this.props.title}</h2>,
                        <h3 className="lead">{this.props.subtitle}</h3>
                      ]
                    } 
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12 text-center">
                    {this.props.children}
                  </div>
                </div>
                {this.props.showScrollTo &&
                  <MouseScroll></MouseScroll>
                }
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
};

Section.defaultProps = {
  showNav: false,
  showScrollTo: true,
  fullScreen: 'vh-100',
  h1: false
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  isDesktop: width >= 1024
})

export default withSizes(mapSizesToProps)(Section);
