import React from 'react';
import Slider from 'react-slick';
import ImageZoom from 'react-medium-image-zoom';
import { track } from './helpers/ga';
import { Modal, ModalBody, ModalFooter } from 'reactstrap';
import { PayPalButton } from 'react-paypal-button-v2';
import { showToast } from './helpers/toast';
import { saveAs } from 'file-saver';
import { log } from './helpers/log';

class Uvp extends React.PureComponent {
  constructor(props) {
    super(props);

    this.downloadDocx = this.downloadDocx.bind(this);
    this.toggleCheckout = this.toggleCheckout.bind(this);
    this.promocodeChanged = this.promocodeChanged.bind(this);
    this.checkShowDiscount = this.checkShowDiscount.bind(this);

    this.state = {
      oldPrice: '19.99',
      price: '9.99',
      promocode: '',
      modal: false,
      email: '',
      emailValid: false,
      dialogClosed: 0
    }

    this.PROMOCODE = 'OFFER2019';
  }

  promocodeChanged(e) {
    this.setState({
      promocode: e.target.value
    }, ()=> {
      if (this.state.promocode === this.PROMOCODE){
        track({ category: 'Discount', action: 'onPromocodeApplied', label: this.state.promocode});
        this.setState({
          price: '7.99'
        });
        return;
      }

      if (this.state.promocode === 'FSR_PP'){
        //track({ category: 'Discount', action: 'onPromocodeApplied', label: this.state.promocode});
        this.setState({
          price: '0.99'
        });
        return 
      }

      this.setState({
        price: '9.99'
      });
    });
  }

  async toggleCheckout() {
    if (this.state.modal === false){
      track({ category: 'Offer', action: 'onOfferShowed'});
    }
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  async downloadDocx(payer){
    log(this.props.template.template);
    log(payer);
    track({ category: 'Delivery', action: 'onResumeDownloaded', label: this.props.template.template});
    const response = await fetch(`/api/download/${this.props.template.template}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payer)
    });
    const json = await response.json();
    log(json);
    saveAs(new Blob([new Uint8Array(json.data)]), `${this.props.template.template}.docx`);
  }


  checkShowDiscount(){
    this.setState({
      dialogClosed: this.state.dialogClosed+1
    }, () => {
      if (this.state.dialogClosed > 1){
        track({ category: 'Discount', action: 'onShowPromocode', label: this.PROMOCODE });
        showToast(`👋 Use ${this.PROMOCODE} promocode for 20% discount now!`, 8000);
      }
    })
  }

  render() {
    log(this.props.template);

    const paypalStyle = 
    {
      layout:  'vertical',
      color:   'gold',
      shape:   'pill',
      label:   'paypal',
    };

    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true
    };

    let imagesList = null;
    if (this.props.template && this.props.template.images) {
      imagesList = this.props.template.images.map((image, index) => (
        <div key = {index} className="row justify-content-center align-items-center">
        <div className="col-12 text-center justify-content-center align-items-center">

              <ImageZoom
                onZoom={() => {
                  track({ category: 'Activation', action: 'onResumeView', label: this.props.template.template });
                }}
                image={{
                  src: image.sm,
                  width: '250px',
                  height: '350px',
                  className: 'img-preview',
                  alt: this.props.template.subtitle,
                  title: this.props.template.subtitle
                }}
                zoomImage={{
                  src: image.lg,
                  alt: this.props.template.subtitle,
                  title: this.props.template.subtitle,
                  className: 'img-preview'
                }}
              />

          </div>
          </div>
      ));
    }

    return (
      <div>
        <Modal isOpen={this.state.modal} onClosed={this.checkShowDiscount} centered={true} backdrop={true} toggle={this.toggleCheckout} className={this.props.className}>
          <div className="modal-header d-none d-md-block">
            <div className="col-12 text-center modal-title">
              <span className="d-none d-md-block h2">Your Six Figure Job Offer</span>
              <span className="d-none d-md-block h4">Is Just One Step Away</span>
            </div>
          </div>
          <ModalBody className="text-center">
            <div className="lead text-shadow-small my-1">
              <i className="icono-checkout-plusCircle"></i> Instant <img width='24px' height='24px' src="https://img.icons8.com/color/48/000000/ms-word.png" alt='Full Stack Resume Word Download'/> Template Download</div>
            <div className="lead text-shadow-small my-1">
              <i className="icono-checkout-plusCircle my-1"></i> Save 12 Hours Of Your Time</div>
            <div className="lead text-shadow-small d-none d-md-block my-1"> 
              <i className="icono-checkout-plusCircle"></i> Get Resume Ready In 30 Minutes </div>
            <div className="lead text-shadow-small d-none d-md-block my-1"> 
              <i className="icono-checkout-plusCircle"></i> 90% More Interview Invitations </div>
            <div className="lead text-shadow-small d-none d-md-block my-1"> 
              <i className="icono-checkout-plusCircle"></i> A/B Tested In Australia 🇦🇺</div>
            <hr className="checkout-hr" />
            <div className="mb-2">
              <div className="mb-2 lead">Only <span className="price"><span className="badge badge-warning"><sup>$</sup><span className="text-shadow">{this.state.price}</span></span></span> USD</div>
              <span className="text-muted small">DOCX <img width='24px' height='24px' src="https://img.icons8.com/color/48/000000/ms-word.png" alt='Full Stack Resume Word Download'/> Version • </span>
              <span className="text-muted small">One-Time Payment • </span>
              <span className="text-muted small">100% Money Back</span>
            </div>
            <PayPalButton 
                style = { paypalStyle }
                amount= { this.state.price}
                options={{
                  //clientId: 'AW7S0o5w3s2hz9kBQ_WHJ2U7iCZgayNvr9szskjUd4VeRaNqzHMilkpmWbd3Phnjxrh71iHRPAE8Ih_R' // sandbox
                  clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID_NEW // prod
              }}
                createOrder={(data, actions) => {
                  log(this.props.template.template);
                  track({ category: 'Offer', action: 'onPaymentStarted', label: this.props.template.template});
                  return actions.order.create({
                    purchase_units: [{
                      amount: {
                        currency_code: "USD",
                        value: this.state.price
                      },
                      description: 'FULLSTACKRESUME.COM'
                    }],
                    application_context: { shipping_preference: "NO_SHIPPING" }
                  });
                }}
                onSuccess={(details, data) => {
                  log(details.payer.email_address);
                  log(details.payer.name.given_name + ' '+details.payer.name.surname);
                  //alert("Transaction completed by " + details.payer.name.given_name);
                  this.toggleCheckout();
                  track({ category: 'Revenue', action: 'onPaymentCompleted', label: this.props.template.template });
                  showToast(`🎉 Yay! Your Resume Is On The Way!`, 5000);
                  this.downloadDocx({
                    email: details.payer.email_address,
                    name: details.payer.name.given_name + ' '+details.payer.name.surname,
                    price: this.state.price
                 });
                }}
              />
              {/*<div className="row justify-content-center align-self-center">
                 <div className="col-12 text-center">
                   <div className="row justify-content-center hide-small mb-2">
                     <span className="small text-muted"><i>― “Only Resume Template I Was Needed!”</i></span>
                   </div>
                   <div className="row justify-content-center hide-small" >
                     <img width='50px' src="https://res.cloudinary.com/practicaldev/image/fetch/s--YEpzC5KO--/c_fill,f_auto,fl_progressive,h_320,q_auto,w_320/https://thepracticaldev.s3.amazonaws.com/uploads/user/profile_image/103024/0fe2caa0-816d-4803-8428-6c921e1ca482.jpeg"
                       className="rounded-circle"/>
                    </div>
                   <div className="row justify-content-center hide-small">
                     <span className="small text-muted"><i>Richard Anthony B. Abear</i></span>
                   </div>
                   <div className="row justify-content-center hide-small">
                     <span className="small text-muted">⭐⭐⭐⭐⭐</span>
                   </div>
                 </div>
              </div>*/}
          </ModalBody>
          <ModalFooter>
          <input type="text" className=" text-center promo-input" placeholder="PROMO 🧡 CODE" 
                value={this.state.promocode}
                onChange={this.promocodeChanged}></input>
            <a className="text-muted small" href="mailto:support@fullstack.cafe">Questions?</a>
          </ModalFooter>
        </Modal>
        <div className="uvp-parent">
          <div className="uvp-preview row justify-content-center align-items-center mt-3">
            <div className="col-12 text-center px-3">
              <Slider className="mb-5 resumeImage" ref={ slider => (this.slider = slider) } {...settings}>
                {imagesList}
              </Slider>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
                <div className="col-12 text-center">
                {/*<div className="row justify-content-center align-items-center">
                <div className="col-12 text-center">
                <input className="input-secondary" type="text" placeholder="Email To Send Resume"
                  value={this.state.email}
                  onChange={this.emailChanged}
                  maxLength="100" />
                 </div>
            </div>*/}
                 <div className="row justify-content-center align-items-center mt-3">
                <div className="col-12 text-center">
                  <button className="btn btn-lg btn-warning" type="button"
                  onClick={() => { this.toggleCheckout()}}>Download&nbsp;
                  <img width='24px' height='24px' src="https://img.icons8.com/color/48/000000/ms-word.png" alt='Full Stack Resume DOC Word Download'/> 
                  &nbsp;Resume Template</button>
                  {/*&nbsp;
                  <button className="btn btn-lg btn-outline-secondary" type="button"
                  onClick={() => { window.open('https://www.fullstack.cafe/?utm_source=fsr&utm_medium=fsr', '_blank');}}>Prepare&nbsp;
                  <img width='24px' height='24px' src="https://user-images.githubusercontent.com/13550565/78226303-18836180-74fe-11ea-8b2b-4964bf3e5798.png" alt='Full Stack Resume DOC Word Download'/> 
                  &nbsp;For Full-Stack Interview</button>*/}
                  &nbsp;
                  <button className="btn btn-lg btn-outline-secondary" type="button"
                  onClick={() => { window.open('https://www.mlstack.cafe/?utm_source=fsr&utm_medium=fsr', '_blank');}}>Prepare&nbsp;
                  <img width='24px' height='24px' src="https://user-images.githubusercontent.com/13550565/78226303-18836180-74fe-11ea-8b2b-4964bf3e5798.png" alt='Go To MLStack.Cafe'/> 
                  &nbsp;For ML & DS Interview</button>
                  &nbsp;
                </div>
                </div>
                </div>
                </div>
        </div>
      </div>
    );
  }
};

export default Uvp;