import React from 'react';
import LazyLoad from 'react-lazy-load';

class BlogPosts extends React.Component {

  render() {
    let articles = this.props.articles;

    if (this.props.articles){
      if (this.props.random){
        articles = articles.sort(() => 0.5 - Math.random());
        //articles = articles.slice(0, this.props.items);
      }

      const articlesList = articles.map((article, index) => (
        <div key = {index}  className="col-md-6 text-center justify-content-center align-items-center m-4">
          <h3 className="display-5"><a href={`/blog/${article.url}`}>{article.title}</a></h3>
          <h4 className="lead mb-4"><a href={`/blog/${article.url}`}>{article.subtitle}</a></h4>
          <a href={`/blog/${article.url}`}> 
            <LazyLoad height={233} offsetVertical={350}>
              <img className="blog-post-image-sm" src={article.img} alt={article.title}></img>
            </LazyLoad>
          </a>
          {/*<div 
            className="addthis_inline_share_toolbox_q7rv mt-3" 
              data-url={`https://www.fullstackresume.com/blog/${article.url}`}
              data-title={`${article.title} | FullStackResume.Com`}
              data-description={article.subtitle}
          data-media={article.img}></div>*/}
        </div>
      ));

      return (
        <div className="uvp-parent">
          <div className="uvp-preview row justify-content-center align-items-center mt-3">
            {articlesList}
          </div>
        </div>
      );
    } else {
      return (<div></div>);
    }
  }
};

BlogPosts.defaultProps = {
  random: false,
  items: 3
};

export default BlogPosts;