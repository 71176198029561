import React from 'react';
import './BlogPost.css';
import {Helmet} from "react-helmet";
import withSizes from 'react-sizes';
import { log } from './helpers/log';

const ReactMarkdown = require('react-markdown/with-html')

class BlogPost extends React.Component {

  render() {
    return (
      <div>
        { this.props.renderTitle ? 
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`${this.props.article.title} Sample | Word/PDF Template + 9 FREE Tips`}</title>
            <meta name="description" content={`Tired of useless resume builders? Looking for real ${this.props.article.title} to edit in Word, improve your CV and get hired FAST? Download DOC template NOW!`}></meta>
            <link rel="canonical" href={this.props.article.canonical} />
            <meta name="twitter:title" content={`${this.props.article.title} | FullStackResume.Com`} />
            <meta name="twitter:description" content={`Tired of useless resume builders? Looking for real ${this.props.article.title} to edit in Word, improve your CV and get hired FAST? Download DOC template NOW!`} />
            <meta name="twitter:image" content={this.props.article.img} />
            <meta property="og:title" content={`${this.props.article.title} | FullStackResume.Com`} />
            <meta property="og:image" content={this.props.article.img} />
            <meta property="og:description" content={`Tired of useless resume builders? Looking for real ${this.props.article.title} to edit in Word, improve your CV and get hired FAST? Download DOC template NOW!`} />
        </Helmet>
        : <div></div>
        }
        <div className="row justify-content-center align-items-center my-5 mx-2">
          <div className="col-12 text-center justify-content-center align-items-center">
            <h3 className={ this.props.isDesktop ? "display-4" : "display-5"}>{this.props.article.title2}</h3>
          </div>
          <div className="col-12 text-center justify-content-center align-items-center">
            <p className="lead mb-4 w-75 mx-auto">
              <ReactMarkdown source={this.props.article.subtitle2} />
            </p>
            {/*<div class="addthis_inline_share_toolbox_q7rv mt-3" 
              data-title={`${this.props.article.title} | FullStackResume.Com`} 
              data-description={`Real ${this.props.article.title} To Download PDF/Word. 90% More Interviews. The Only Developer Resume Template You Need To Land The Job.`} 
              data-media={this.props.article.img}></div>*/}
          </div>
        </div>
        <div className="container">
          <div className="row m-3">
            <div className="col-12">
              <ReactMarkdown escapeHtml={false}  source={this.props.article.content} />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 480,
  isDesktop: width >= 1024
})

BlogPost.defaultProps = {
  renderTitle: true
}

export default withSizes(mapSizesToProps)(BlogPost);