import React from 'react';

class Benefits extends React.PureComponent {
  render() {
    return (
      <div className="row mt-3 mb-5 mx-5 text-center">
        <div className="col-md-3 my-2">
          <div className="m-3">
            <img src="https://img.icons8.com/nolan/64/000000/mark-as-favorite.png" width="50px" alt="One Sheet Long Developer Resume"/>
          </div>
          <h4>1 Sheet Long</h4>
          <span className="">It's Short, Succinct, Pixel-Perfect and Straight To The Point. Like You Are.</span>
        </div>
        <div className="col-md-3 my-2">
        <div className="m-3">
            <img src="https://img.icons8.com/nolan/64/000000/google-alerts.png" width="50px" alt="Developer Resume Best Practices"/>
          </div>
          <h4>9 Best Practices</h4>
          <span className="">Packed With 9 Unique Dev's Resume Writing Practices. No Fluff and BS.</span>
          </div>
        <div className="col-md-3 my-2">
        <div className="m-3">
            <img src="https://img.icons8.com/nolan/64/000000/ms-word.png" width="50px" alt="90% More Developer Interviews"/>
          </div>
          <h4>100% Editable</h4>
          <span className="">F*ck Useless Resume Builders. It's 100% Word Compatible and 100% Yours.</span>
          </div>
          <div className="col-md-3 my-2">
        <div className="m-3">
            <img src="https://img.icons8.com/nolan/64/000000/merge-horizontal.png" width="50px" alt="A/B Tested Developer Resume"/>
          </div>
          <h4>A/B Tested</h4>
          <span className="">Tested On Australian IT Market as a Winner Of 6-Figure Job Offers</span>
          </div>
      </div>
    );
  }
};

export default Benefits;