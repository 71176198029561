import React from 'react';
import { Link } from 'react-router-dom';

class Terms extends React.PureComponent {

  render() {
    return (
      <div className="row justify-content-center align-items-center m-3">
        <div className="col-12 text-center justify-content-center align-items-center">
          <span className="small text-muted"><span className="small">Made with ❤️ in 🇦🇺 by <a href='https://www.indiehackers.com/aershov' target='_blank'>Alex Ershov</a></span></span>
          <br/>
          <span className="small text-muted">
            <span className="small"><Link to="/">Home</Link> • &nbsp;
              <a href='https://www.fullstack.cafe/terms' target='_blank'>Terms</a> • &nbsp;
              <a href='https://www.fullstack.cafe/privacy' target='_blank'>Privacy</a> • &nbsp;
              <a href='mailto:support@fullstack.cafe'>Contacts</a> • &nbsp;
              <a href='https://www.fullstack.cafe'>FullStack.Cafe</a> • &nbsp;
              <a href='https://www.mlstack.cafe'>MLStack.Cafe</a></span>
          </span>
        </div>
      </div>
    );
  }
};

export default Terms;