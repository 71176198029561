import React from 'react';
import './App.css';
import Section from './components/Section';
import Uvp from './components/Uvp';
import Terms from './components/Terms';
import Benefits from './components/Benefits';
import BlogPosts from './components/BlogPosts';
import { Helmet } from "react-helmet";
import BlogPost from './components/BlogPost';
import { log } from './components/helpers/log';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        article: {},
        howTo: {}
    };
  }

  componentDidMount() {
    fetch(`/api/blog`)
    .then(data => data.json())
    .then((data) => { 
      log(data);
      this.setState({ articles: data }) 
    });

    const { params } = this.props.match;

    // if blog post url
    if (params.url){
      fetch(`/api/blog/${params.url}`)
      .then(data => data.json())
      .then((data) => { 
        if (data)
        this.setState({ article: data }) 
      }); 
    } else {
      fetch(`/api/blog/full-stack-developer-resume-sample`)
      .then(data => data.json())
      .then((data) => { 
        log(data);
        this.setState({ article: data }) 
      }); 
    }

    fetch(`/api/blog/how-to-write-full-stack-developer-resume`)
    .then(data => data.json())
    .then((data) => { 
      log(data);
      this.setState({ howTo: data }) 
    }); 
  }

  render() {
    const { params } = this.props.match;
    log(this.state.article);
    log(params);

    return (
      <div className="container">
        { params.url ? 
          <>
            <Section 
              showScrollTo={false}
              showNav={false}
              background='bg' 
              header='Real, A/B Tested, Job-Winning'
              title={this.state.article.title}
              subtitle={this.state.article.subtitle}
              h1>
              <Uvp template={this.state.article}>
              </Uvp>
            </Section>
            <BlogPost article={this.state.article}>
            </BlogPost>
          </>
        :
          <>
            <Helmet>
              <meta charSet="utf-8" />
              <title>Job-Winning Developer Resume Samples | Word/PDF Ready </title>
              <meta name="description" content="The only Developer Resume template you need to get hired fast. For freshers and experienced web developers. Download PDF/Word version Now!"></meta>
              <meta name="twitter:title" content="Full Stack Developer Resume Example | FullStackResume.Com"/>
              <meta name="twitter:description" content="The Only Developer Resume Template You Need To Land Dev Job. Download PDF/Word Version." />
              <meta name="twitter:image" content="https://images.pexels.com/photos/7107/notebook-hero-workspace-minimal.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500" />
              <meta property="og:title" content="Full Stack Developer Resume Example | FullStackResume.Com"/>
              <meta property="og:image" content="https://images.pexels.com/photos/7107/notebook-hero-workspace-minimal.jpg?auto=compress&cs=tinysrgb&dpr=2&w=500" />
              <meta property="og:description" content="The Only Developer Resume Template You Need To Land Dev Job. Download PDF/Word Version." />
          </Helmet>
          <Section 
            showScrollTo={false}
            showNav={false}
            background='bg' 
            header={'Get 90% More Interview Invitations'}
            title='Developer Resume. Done Right.' 
            subtitle='Land Your Next 6-Figure Job Offer'
            h1>
            <Uvp template={this.state.article}>
            </Uvp>
          </Section>
        </>
      }
        <Section  title="Why Use This Template?"  showScrollTo={false} fullScreen={false} subtitle="All You Need To Get 90% More Invitations In One CV">
            <Benefits></Benefits>
            <Uvp template={this.state.article}>
            </Uvp>
            <div className="mb-5"></div>
          </Section>
          <BlogPost article={this.state.howTo} renderTitle={false}>
          </BlogPost>
          <Uvp template={this.state.article} >
          </Uvp>
          <div className="mb-5"></div>
          <Section className="mt-5" showScrollTo={false} title="Developer Resume Samples" subtitle="More Developer Resume Templates To Download">
            <BlogPosts articles={this.state.articles} random={true} items={3} />
            <Terms />
          </Section>
      </div>
    );
  }
}

export default App;
