import ReactGA from 'react-ga';
import { log } from './log';

const track = (e) => {
  log(e);

  if (process.env.REACT_APP_ENV !== 'prod'){
    //log(process.env.REACT_APP_ENV);
    //return;
  }

  ReactGA.initialize('UA-143331093-1');
  
  ReactGA.event({
    category: e.category,
    action: e.action,
    label: e.label,
    value: e.value
  });
};

export { track };

