import React from 'react';
import { Link } from 'react-router-dom';

class Navigation extends React.PureComponent {

  render() {
    return (
      <div>
        <div className="row justify-content-center align-items-center">
          <div className="col-12 text-center justify-content-center align-items-center">
            <span className="small text-muted"><span className="small"><Link to="/">{this.props.placeholder}</Link></span></span>
          </div>
        </div>
      </div>
    );
  }
};

export default Navigation;